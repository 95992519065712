import { ContextSidebar } from '@/components/sidebars/context/ContextSidebar';
import { ContextSidebarMenu } from '@/components/sidebars/context/ContextSidebarMenu';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { IMenu } from '@/typings';

export default createComponent({
  name: 'LogsSidebar',
  setup(props, ctx) {
    const menus: IMenu[] = [
      {
        text: 'Logs do sistema',
        header: true,
      },
      {
        text: 'Agendamentos',
        icon: MyIcons.agenda,
        to: Routes.app.logs.agenda,
      },
      {
        text: 'Finanças',
        icon: MyIcons.financas,
        to: Routes.app.logs.financas,
      },
    ];

    return () => (
      <ContextSidebar>
        <ContextSidebarMenu menus={menus} />
      </ContextSidebar>
    );
  },
});
