import { DataTable } from '@/components/datatable/DataTable';
import { MyPage } from '@/components/page/MyPage';
import { createComponent } from '@/lib/vue';
import { IDataTableHeader } from '@/typings';

export default createComponent({
  name: 'LogsAgendaPage',
  setup(props, ctx) {
    const items = [];

    const headers: IDataTableHeader[] = [
      {
        text: 'Paciente',
        value: 'paciente',
      },
      {
        text: 'Profissional de saúde',
        value: 'profissional',
      },
      {
        text: 'Telefone',
        value: 'telefone',
      },
      {
        text: 'Horário',
        value: 'horario',
      },
    ];

    return () => (
      <MyPage title="Log do evento">
        <DataTable
          headers={headers}
          items={items}
          // loading={loading}
        />
      </MyPage>
    );
  },
});
